.slider-filter {
  background: #fff;
  padding: 1rem;
}

.filters {
  display: flex;
  padding-left: 50px;
}

.add-filter {
  align-self: flex-end;
  margin-bottom: -20px;
  margin-left: 15px;
}

.chart-container {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 20px 0;
  overflow-x: auto;
}

.chart-measurement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  position: relative;
  padding: 0 20px 0 0;
}

/* .chart-measurement::before {
  content: 'HIGHEST';
  position: absolute;
  top: -25px;
  left: 0;
}

.chart-measurement::after {
  content: 'LOWEST';
  position: absolute;
  bottom: -35px;
  left: 0;
} */

.chart {
  position: relative;
  width: 100%;
}

.chart-background {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.chart-content {
  padding: 2px 0 0;
  position: relative;
  z-index: 2;
}

.chart-col {
  min-width: 60px;
  width: 10%;
  border-left: 1px dashed rgba(224, 231, 255, 0.7);
  height: 100%;
}

.chart-row {
  height: 20%;
  width: 100%;
  border-top: 1px dashed rgba(224, 231, 255, 0.7);
  border-bottom: 1px dashed rgba(224, 231, 255, 0.7);
}
