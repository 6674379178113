.MuiSlider-track {
  border-radius: 8px;
  width: 6px !important;
}

.MuiSlider-rail {
  background: rgba(46, 91, 255, 0.3);
  border-radius: 8px;
  width: 6px !important;
}

.MuiSlider-root.MuiSlider-vertical {
  padding: 0 20px;
}

.MuiSlider-markLabel,
.MuiSlider-mark {
  display: none !important;
}

.MuiSlider-thumb {
  margin-left: -18px !important;
  border-radius: 0 !important;
  margin-bottom: -3px !important;
}

.MuiSlider-thumb[data-index='1'] {
  background: #004bb3 !important;
  width: 32px !important;
  padding: 10px !important;
  height: 28px !important;
  border-radius: 3px !important;
  margin-left: -14px !important;
  margin-bottom: -12px !important;
}

.MuiSlider-thumb[data-index='1']::before {
  content: '';
  border-top: 2px solid #fff;
  height: 6px;
  border-bottom: 2px solid #fff;
  position: absolute;
  width: 50%;
  top: 9px;
  left: 25%;
  border-radius: 1px;
}

.MuiSlider-thumb[data-index='1']::after {
  content: '';
  border-bottom: 2px solid #fff;
  width: 50%;
  bottom: 9px;
  left: 25%;
  top: auto !important;
  border-radius: 0 !important;
  border-radius: 1px;
}

.single-filter-container {
  position: relative;
}

.single-filter-container.filter-level-4 {
  margin-top: 120px;
  height: 80px;
}

.single-filter-container.filter-level-7 {
  margin-top: 80px;
  height: 120px;
}

.single-filter-container.filter-level-10 {
  margin-top: 40px;
  height: 160px;
}

.single-filter-container.filter-level-13 {
  height: 200px;
}

.remove-filter {
  opacity: 0;
  pointer-events: none;
  position: absolute !important;
  top: 0;
  left: 60%;
  margin: 0 !important;
  padding: 0 !important;
  transition: 0.3s opacity !important;
}

.single-filter-container:hover .remove-filter {
  opacity: 1;
  pointer-events: all;
}

.single-filter {
  margin: 0 7px;
}

.single-slider__footer {
  padding: 4px;
  width: 100%;
}

.single-slider__footer h3 {
  margin: 0;
  text-transform: uppercase;
  font-size: 0.65rem;
  margin-left: -25%;
}
