.table--borderless .MuiTableCell-body {
  border: 0 !important;
  line-height: 1.2;
  padding-top: 3px;
  padding-bottom: 3px;
}

.table--borderless .MuiTableCell-sizeSmall {
  padding: 4px 10px !important;
}

.table--borerless .MuiTableRow-root {
  display: block;
}

.MuiTableRow-root {
  transition: 0.2s background;
}

.selected-row {
  background: #fff !important;
  position: relative;
}

.selected-row::before {
  position: absolute;
  left: -2px;
  top: -1px;
  height: 100%;
  width: 2px;
  content: '';
  background: #3557CD;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.request-list__item {
  display: flex;
  justify-content: space-between;
  padding: 0 5% 5px;
  border-bottom: 1px dashed #3557CD;
}

.request-list .request-summary:last-of-type .request-list__item {
  border-bottom: 0;
}

.request-list__item__detail {
  width: 31.333%;
}
