.requests-container {
  background: #F4F6FC;
  padding-bottom: 20px;
  max-height: 100%;
  overflow-x: auto;
}

.table-cell__heading span {
  margin-top: 15px;
  line-height: 1.1;
  margin-bottom: 6px;
}

.table-cell__heading .MuiTypography-root {
  padding-right: 15px;
  margin-top: 10px;
}
