.file-upload {
  width: 90%;
  max-width: 440px;
  border: 1px dashed #2e384d;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 25px auto;
  position: relative;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload__label {
  text-align: center;
  margin: 25px 0;
  font-size: 0.75rem;
}

.file-activator {
  margin: 25px auto;
  text-align: center;
}

.file-image {
  max-width: 340px;
  max-height: 340px;
}

.skip-button {
  display: flex;
  justify-content: center;
}